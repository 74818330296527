import React from "react";
import Layout from "../components/layout";
import ContactForm from "../components/contactForm";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import BottomBanner from "../components/bottom-banner";
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import { StaticImage } from "gatsby-plugin-image";
import HubspotForm from "react-hubspot-form";

const ContactUsPage = ({ data }) => {
	const { siteUrl } = data.site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Contact Us",
				item: {
					url: `${siteUrl}/contact-us`,
					id: `${siteUrl}/contact-us`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Contact Us | Eastbourne Carnival"
				description="Contact page Eastbourne Carnival."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/contact-us`,
					title: "Contact Us | Eastbourne Carnival",
					description: "Contact page Eastbourne Carnival.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>
			<section className="pb-md-10 pt-lg-7 py-5">
				<Container>
					<Row>
						<Col>
							<h1 className="text-secondary carnival-normal display-4 text-center pb-4 pb-lg-7">
								Get in touch
							</h1>
						</Col>
					</Row>
					<Row>
						<Col
							lg={4}
							className="position-relative text-center pb-4 pb-lg-0 text-lg-start"
						>
							<p className="shortstack text-secondary pb-0 mb-0 fs-4">Email</p>
							<a
								href="mailto:info@eastbournecarnival.com"
								className="jost-light link-black text-decoration-none fs-5"
							>
								info@eastbournecarnival.com
							</a>
							<p className="shortstack text-secondary pb-0 mb-0 fs-4 pt-5">
								Address
							</p>
							<p>
								Suite 4<br className="d-none d-lg-block" /> Chamber House{" "}
								<br className="d-none d-lg-block" /> 49 Gildredge Road{" "}
								<br className="d-none d-lg-block" /> Eastbourne
								<br className="d-none d-lg-block" /> BN21 4RY
							</p>
							{/* <StaticImage
								src="../images/festival.svg"
								style={{
									zIndex: -1,
									overflow: "visible",
									left: "-10%",
									maxWidth: "60%",
								}}
								className="position-absolute d-none d-lg-block bottom--30  "
								quality={100}
								placeholder="Traced_Svg"
							/> */}
						</Col>
						<Col lg={8}>
							<HubspotForm
								region="eu1"
								portalId="25477959"
								formId="bd94b068-0a17-4ac8-a33c-9484ac534b88"
								onSubmit={() => console.log("Submit!")}
								onReady={(form) => console.log("Form ready!")}
								loading={<div>Loading...</div>}
							/>
							{/* <ContactForm /> */}
						</Col>
					</Row>
				</Container>
			</section>
			<BottomBanner
				mainBg="bg-secondary"
				position="top--20"
				content={
					<Row>
						<Col className="py-5 pb-md-0 mt-md-5 pt-md-10">
							<h2 className="text-white text-center  fs-1 shortstack">
								FOLLOW US ON SOCIAL MEDIA
							</h2>
							<div className="d-flex pt-4 justify-content-center align-items-center">
								<a
									rel="noreferrer"
									target="_blank"
									href="https://www.facebook.com/eastbournecarnival"
								>
									<FaFacebookF className="text-white display-2 link-white" />
								</a>
								<a
									rel="noreferrer"
									target="_blank"
									href="https://twitter.com/EBCarnival"
								>
									<FaTwitter className="text-white display-2 ms-5 link-white" />
								</a>
								<a
									rel="noreferrer"
									target="_blank"
									href="https://www.instagram.com/eastbournecarnival/"
								>
									<FaInstagram className="text-white display-2 ms-5 link-white" />
								</a>
								<a
									rel="noreferrer"
									target="_blank"
									href="https://www.youtube.com/@eastbournecarnival990"
								>
									<FaYoutube className="text-white display-2 ms-5 link-white" />
								</a>
							</div>
						</Col>
					</Row>
				}
			/>
		</Layout>
	);
};

export const query = graphql`
	query {
		site {
			siteMetadata {
				siteUrl
			}
		}
		heroImg: wpMediaItem(title: { eq: "Home-Hero-Image" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					original {
						height
						width
					}
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
	}
`;

export default ContactUsPage;
