import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function ContactForm() {
	const [state, handleSubmit] = useForm("xzboblvd");
	if (state.succeeded) {
		return (
			<div>
				{" "}
				<Form className="w-100" onSubmit={handleSubmit}>
					<Form.Group className="mb-3 " controlId="name">
						<Form.Control name="name" placeholder="Name" type="text" />
					</Form.Group>
					<ValidationError prefix="Name" field="name" errors={state.errors} />
					<Form.Group className="mb-3" controlId="telephone">
						<Form.Control
							type="tel"
							name="telephone"
							placeholder="Telephone Number"
						/>
					</Form.Group>

					<ValidationError
						prefix="Telephone"
						field="telephone"
						errors={state.errors}
					/>
					<Form.Group className="mb-3" controlId="email">
						<Form.Control
							name="_replyto"
							type="email"
							placeholder="Email Address"
						/>
					</Form.Group>
					<ValidationError prefix="Email" field="email" errors={state.errors} />

					<Form.Group className="mb-3" controlId="message">
						<Form.Control
							name="message"
							placeholder="Message"
							as="textarea"
							rows={3}
						/>
					</Form.Group>

					<ValidationError
						prefix="Message"
						field="message"
						errors={state.errors}
					/>

					{/* <div
        class="g-recaptcha"
        data-sitekey=""
      ></div> */}
					<Button
						className="btn btn-primary w-100 w-md-auto mt-3 py-3 px-4 text-white jost-normal"
						type="submit"
						id="contact-send-btn"
						disabled={state.submitting}
					>
						Send Message
					</Button>
				</Form>
				<div className="d-none">{(window.location = "/thank-you")}</div>
			</div>
		);
	}
	return (
		<Form className="w-100" onSubmit={handleSubmit}>
			<Form.Group className="mb-3" controlId="name">
				<Form.Control name="name" placeholder="Name" type="text" />
			</Form.Group>
			<ValidationError prefix="Name" field="name" errors={state.errors} />
			<Form.Group className="mb-3" controlId="telephone">
				<Form.Control
					type="tel"
					name="telephone"
					placeholder="Telephone Number"
				/>
			</Form.Group>

			<ValidationError
				prefix="Telephone"
				field="telephone"
				errors={state.errors}
			/>
			<Form.Group className="mb-3" controlId="email">
				<Form.Control
					name="_replyto"
					type="email"
					placeholder="Email Address"
				/>
			</Form.Group>
			<ValidationError prefix="Email" field="email" errors={state.errors} />

			<Form.Group className="mb-3" controlId="message">
				<Form.Control
					name="message"
					placeholder="Message"
					as="textarea"
					rows={3}
				/>
			</Form.Group>

			<ValidationError prefix="Message" field="message" errors={state.errors} />

			{/* <div
        class="g-recaptcha"
        data-sitekey=""
      ></div> */}
			<Button
				className="btn btn-primary w-100 w-md-auto mt-3 py-3 px-4 text-white jost-normal"
				type="submit"
				id="contact-send-btn"
				disabled={state.submitting}
			>
				Send Message
			</Button>
		</Form>
	);
}
export default ContactForm;
